@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #f6f6f5;
  scroll-behavior: smooth;
  @apply bg-stone-600 no-scrollbar;
}

/* Custom CSS*/

/* Prevent autofill from graying out input fields */
@layer components {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: white;
    caret-color: white;
    transition: background-color 5000s ease-in-out 0s;
  }
}

/* Cart remove item css */
@keyframes slideLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Hiding the increment decrement button on number input. */
@layer utilities {
  .no-spinner::-webkit-inner-spin-button,
  .no-spinner::-webkit-outer-spin-button {
    @apply appearance-none;
    margin: 0;
  }
  .no-spinner {
    -moz-appearance: textfield;
  }
  .no-spinner::-ms-clear,
  .no-spinner::-ms-reveal {
    @apply hidden;
  }
}

